import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Encoding from 'encoding-japanese';
import { createHead } from '@vueuse/head'
import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/ja'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import constants from './resources/constants.json'
import { logout } from './resources/logout.js';
import AsyncResizeObserver from "@/services/utils/resizeObserverService";

// リサイズ挙動の調整
window.ResizeObserver = AsyncResizeObserver as unknown as typeof ResizeObserver;

////////////////////////////////////
// 多言語化 {{$t("test.test1")}}
////////////////////////////////////
const messages = {
    ja: {
        config: {
            domain: `${process.env.VUE_APP_API_DOMAIN}`,
            youtube_domain: `${process.env.VUE_APP_YOUTUBE_API_DOMAIN}`,
            api_token_cookie_name: 'api_token'
        },
        errors: {
            error: 'エラー',
            fetch_fail: '予期せぬエラーが発生しました。時間をおいてやり直すか、管理者にご連絡ください。',
            validate_error: '不正な値を検知しました。',
            expire_auth: 'failed login.',
        }
    }
}

const i18n = createI18n({
    locale: 'ja', // set locale
    messages, // set locale messages
})

const head = createHead()

// コンポーネントの追加
const app = createApp(App);
app.use(ElementPlus, { locale });
app.use(router);
app.use(VueAxios, axios);
// @ts-ignore
app.use(Encoding);
app.use(head);
app.use(i18n);

// element plusのコンポーネント読み込み
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 共通処理読み込み
app.config.globalProperties.$logout = logout;
// 定数読み込み
app.config.globalProperties.$CONSTANTS = constants;

app.mount('#app')
