<template>
  <el-menu
      class="header-nav-menu"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" style="color:#303133 !important;" @click="switchCollapse()">
      <el-icon class="px-md-1" v-show="isCollapse"><Expand /></el-icon>
      <el-icon class="px-md-1" v-show="!isCollapse"><Fold /></el-icon>
    </el-menu-item>
    <div class="flex-grow" />

    <el-sub-menu index="1" style="color:#303133 !important;">
      <template #title>
        <HomeFilled class="header-icon" />
        {{ this.isWebSize ? loginUser.accountName : '' }}
      </template>
      <el-menu-item v-for="(account, key) in this.loginUser.accounts" :key="key" :index="'1-'+key" @click="changeAccount(account.id)">{{ account.name }}</el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="2" style="color:#303133 !important;">
      <template #title>
        <UserFilled class="header-icon" />
        {{ this.isWebSize ? loginUser.name : '' }}
      </template>
      <el-menu-item index="2-1" @click="toSetting()">設定</el-menu-item>
      <el-menu-item index="2-2" @click="toChangePassword()">パスワード変更</el-menu-item>
      <el-menu-item index="2-3" @click="logout()">ログアウト</el-menu-item>
    </el-sub-menu>

  </el-menu>
</template>

<script>
import CONSTANTS from "@/resources/constants.json";

export default {
  name: 'Header',

  components: {

  },

  props: {
    loginUser: {
      type: Object,
      default: null
    },
    isCollapse: {
      type: Boolean,
      default: false
    },
    isWebSize: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    switchCollapse: function () {
      this.$emit('switchCollapse', true);
    },

    /* 設定画面への遷移 */
    toSetting: function () {
      if (this.$route.name == 'Setting') {
        return;
      }
      this.$emit('loading', true);
      const param = this;
      setTimeout(function() {
        param.$router.push({name: 'Setting'});
      }, 300);
    },

    /* パスワード変更画面への遷移 */
    toChangePassword: function () {
      if (this.$route.name == 'ChangePassword') {
        return;
      }
      this.$emit('loading', true);
      const param = this;
      setTimeout(function() {
        param.$router.push({name: 'ChangePassword'});
      }, 300);
    },

    /* アカウントの切り替え */
    changeAccount: function (id) {
      this.$emit('loading', true);
      const api_token = window.getCookie(`${CONSTANTS.api_token_name}`);

      const url = new URL(
          `${process.env.VUE_APP_API_DOMAIN}/api/v1/user_account/select`
      );

      let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${api_token}`
      };

      let responseStatus = 200;

      fetch(url, {
        method: "POST",
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({
          "account_id": id
        })
      })

          .then(response => {
            if (!response.ok) {
              responseStatus = response.status;
            }
            return response.json();
          })

          .then((json) => {
            if (responseStatus !== 200) {
              if (responseStatus === 500) {
                this.$emit('showError', `${CONSTANTS.errors.unexpected_error}`);
              } else {
                this.$emit('showError', [json.message]);
              }
              return false;
            }
            if (this.$route.name === 'Top') {
              location.reload();
            } else {
              this.$emit('access');
              this.$router.push({name: 'Top'});
            }
          })

          .catch(() => {
            this.$emit('showError', `${CONSTANTS.errors.unexpected_error}`);
          })

          .finally(() => {
            this.$emit('loading', false);
          });
    },

    /* ログアウト */
    logout: function () {
      this.$emit('loading', true);
      this.$logout(this.loginUser.id);
    },
  },

  mounted() {

  }
}

</script>
