<template>
  <router-view
      @loading="loading"
      @showError="showError"
      @showErrorModal="showErrorModal"
      @showSuccess="showSuccess"
      @showSuccessModal="showSuccessModal"
  ></router-view>
</template>

<script>
import { h } from 'vue'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: 'UnLogin',

  components: {

  },

  setup() {
    const loader = ElLoading.service({
      lock: true
    });

    return {
      loader
    };
  },

  methods: {
    loading: function (bool) {
      if (bool == true) {
        this.loader = ElLoading.service({
          lock: true
        });
      } else {
        this.loader.close();
      }
    },

    showError: function (errors) {
      let message = errors.join('<br>');

      ElMessage({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: '<p>'+message+'</p>',
        type: 'error',
      })
    },

    showErrorModal: function (errorTitle, errorText, errorLink, nextAction) {
      let messages = [];
      if (errorText != null) {
        errorText.forEach(text => {
          let message = h('span', null, text);
          messages.push(message);
          messages.push(h('br', null, null));
        });
      }
      if (errorLink != null) {
        messages.push(h(
            'a',
            {href: errorLink, target: "_blank"},
            errorLink
        ))
      }

      ElMessageBox({
        title: errorTitle,
        message: h('p', null, messages),
        showCancelButton: false,
        confirmButtonText: 'OK',
        cancelButtonText: '',
        type: 'error',
        center: true,
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            switch (nextAction) {
              case 'close':
                break;
              case 'logout':
                this.$logout(null);
                break;
              case 'reload':
                location.reload();
                break;
              default:
                break;
            }
          } else {
            switch (nextAction) {
              case 'close':
                break;
              case 'logout':
                this.$logout(null);
                break;
              case 'reload':
                location.reload();
                break;
              default:
                break;
            }
            done()
          }
        },
      })
    },

    showSuccess: function (messages) {
      let message = messages.join('<br>');

      ElMessage({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: '<p>'+message+'</p>',
        type: 'success',
      })
    },

    showSuccessModal: function (title, text, action) {
      this.$refs.SuccessModal.show(title, text, action);
    }
  },

  mounted() {

  }
}
</script>
