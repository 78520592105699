<template>
  <el-menu
      :default-active="activeIndex"
      class="sidebar"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
      router
  >
    <el-menu-item class="p-0" :route="{ name: 'Top' }">
      <img src="/assets/images/logo.png" class="header-brand-img mx-auto" alt="" v-show="!isCollapse">
      <img src="/assets/images/logo-sm.png" class="header-brand-img mx-auto" alt="" v-show="isCollapse">
    </el-menu-item>

    <template v-for="(menuItem, key) in this.menuItems" :key="key">
      <el-menu-item
          v-if="menuItem.type == 'route'"
          :index="menuItem.pageName"
          :route="menuItem.pageName.indexOf('http') !== 0 ? { name: menuItem.pageName } : { name: ''}"
          @click="menuItem.pageName.indexOf('http') === 0 ? toPage(menuItem.pageName) : ''"
          v-show="
					this.loginUser.authority === 'master' ? menuItem.authority === 'master' || menuItem.authority === 'admin' || menuItem.authority === 'all' :
					this.loginUser.authority === 'admin' ? menuItem.authority === 'admin' || menuItem.authority === 'adminOnly' || menuItem.authority === 'all' :
					menuItem.authority === 'all'
				"
      >
        <el-icon>
          <component :is="menuItem.icon"></component>
        </el-icon>
        <template #title>{{ menuItem.label }}</template>
      </el-menu-item>

      <el-sub-menu
          v-if="menuItem.type == 'group'"
          :index="menuItem.pageGroup"
          v-show="
					this.loginUser.authority === 'master' ? menuItem.authority === 'master' || menuItem.authority === 'admin' || menuItem.authority === 'all' :
					this.loginUser.authority === 'admin' ? menuItem.authority === 'admin' || menuItem.authority === 'adminOnly' || menuItem.authority === 'all' :
					menuItem.authority === 'all'
				"
      >
        <template #title>
          <el-icon>
            <component :is="menuItem.icon"></component>
          </el-icon>
          <span>{{ menuItem.label }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
              v-for="(childItem, key) in menuItem.childItems"
              :key="key"
              v-show="
                this.loginUser.authority === 'master' ? childItem.authority === 'master' || childItem.authority === 'admin' || childItem.authority === 'all' :
                this.loginUser.authority === 'admin' ? childItem.authority === 'admin' || childItem.authority === 'adminOnly' || childItem.authority === 'all' :
                childItem.authority === 'all'
              "
              :index="childItem.pageName"
              :route="childItem.pageName.indexOf('http') !== 0 ? { name: childItem.pageName } : { name: ''}"
              @click="childItem.pageName.indexOf('http') === 0 ? toPage(childItem.pageName) : ''"
          >
            {{ childItem.label }}
          </el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
    </template>
  </el-menu>
</template>

<script>
// modules
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
// data
import sidebarItems from './../resources/sidebarItems.json'

export default {
  name: 'SideMenu',

  components: {

  },

  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    loginUser: {
      type: Object,
      default: null
    },
    accountMenus: {
      type: Array,
      default: () => []
    }
  },

  setup() {
    const route = useRoute();

    const activeIndex = computed( () => {
      return route.name;
    })

    const menuItems = ref([]);
    const baseItems = sidebarItems;

    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath)
    }

    const handleClose = (key, keyPath) => {
      console.log(key, keyPath)
    }


    return {
      activeIndex,
      menuItems,
      baseItems,
      handleOpen,
      handleClose
    };
  },

  methods: {
    toPage: function(url) {
      window.open(url, '_blank');
    },
    createSideMenu: function() {
      if (this.loginUser && this.loginUser.authority === 'master') {
        this.menuItems = this.baseItems;
        return;
      }


      if (!Array.isArray(this.accountMenus)) {
        console.error('accountMenus prop is not in expected format:', this.accountMenus);
        return;
      }


      const activeMenuNames = this.accountMenus
        .filter(menu => menu.is_deleted === 0)
        .map(menu => menu.name);

      const controlledLabels = ['アカウント設定', 'Equipmedia', 'YouTube', 'FTP'];

      this.menuItems = this.baseItems.map(item => {
        if (item.childItems && item.childItems.length > 0) {
          item.childItems = item.childItems.filter(childItem => {
            return !controlledLabels.includes(childItem.label) || activeMenuNames.includes(childItem.label);
          });
        }
        return item;
      }).filter(item => {
        return (item.childItems && item.childItems.length > 0) || !controlledLabels.includes(item.label) || activeMenuNames.includes(item.label);
      });
    }
  },

  /* createSideMenu: function() {
      const sidebarItems = this.baseItems;
      const menuSetting = this.accountSetting.menuSetting;
      if(menuSetting.equipmediaEnabled === false) {
        sidebarItems.forEach(item => {
            if(item.pageGroup === "deliveries") {
                item.childItems = item.childItems.filter(child => child.pageName !== "Equipmedia");
            }
        });
      }
      if(menuSetting.youtubeEnabled === false) {
        sidebarItems.forEach(item => {
            if(item.pageGroup === "deliveries") {
                item.childItems = item.childItems.filter(child => child.pageName !== "YoutubeChannel");
            }
        });
      }
      this.menuItems = sidebarItems;
    }
  }, */

  mounted() {
    this.createSideMenu();
  }
}
</script>
