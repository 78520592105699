import { createRouter, createWebHistory } from 'vue-router'
import AdminIndex from '../AdminIndex.vue'
import UnLogin from '../UnLogin.vue'
import Youtube from '../Youtube.vue'
import Eq from '../Eq.vue'

const routes = [
	{
		// ルート
		path: '',
		name: 'Root',
		component: () => import('../views/Root.vue')
	},
	{
		path: '',
		component: UnLogin,
		children: [
			/**
			 * 認証
			 */
			{
				// ログイン画面
				path: '/login',
				name: 'Login',
				meta: {
					title: 'ユーザーログイン',
				},
				component: () => import('../views/Auth/Login.vue')
			},
			{
				// パスワード忘れ画面
				path: '/password/forgot',
				name: 'ForgotPassword',
				meta: {
					title: 'パスワード再設定',
				},
				component: () => import('../views/Auth/ForgotPassword.vue')
			},
			{
				// 仮パスワード確認画面
				path: '/password/check/:hash',
				name: 'CheckPassword',
				meta: {
					title: 'パスワード検証',
				},
				component: () => import('../views/Auth/CheckPassword.vue')
			},
			{
				// パスワード再設定画面
				path: '/password/update/:hash',
				name: 'UpdatePassword',
				meta: {
					title: 'パスワード再設定',
				},
				component: () => import('../views/Auth/UpdatePassword.vue')
			},
			{
				// アカウント選択画面
				path: '/select_account',
				name: 'SelectAccount',
				meta: {
					title: 'アカウント選択',
				},
				props: true,
				component: () => import('../views/Auth/SelectAccount.vue')
			},
		],
	},
	{
		path: '/admin',
		component: AdminIndex,
		children: [
			/**
			 * TOP
			 */
			{
				// TOP
				path: '',
				name: 'Top',
				meta: {
					title: 'Top',
				},
				component: () => import('../views/Top.vue')
			},

			// {
			// 	// Xの調査
			// 	path: 'x-test',
			// 	name: 'XTest',
			// 	meta: {
			// 		title: 'XTest',
			// 	},
			// 	component: () => import('../views/X/XTest.vue')
			// },

			/**
			 * アカウント管理
			 */
			{
				// アカウント一覧
				path: 'accounts',
				name: 'Accounts',
				meta: {
					title: 'Accounts',
				},
				component: () => import('../views/Accounts/Index.vue')
			},
			{
				// アカウント登録
				path: 'account/create',
				name: 'CreateAccount',
				meta: {
					title: 'CreateAccount',
				},
				component: () => import('../views/Accounts/Create.vue')
			},
			{
				// アカウント詳細
				path: 'accounts/:id',
				name: 'ShowAccount',
				meta: {
					title: 'ShowAccount',
				},
				component: () => import('../views/Accounts/Show.vue')
			},
			{
				// アカウント編集
				path: 'accounts/edit/:id',
				name: 'EditAccount',
				meta: {
					title: 'EditAccount',
				},
				component: () => import('../views/Accounts/Edit.vue')
			},
			{
				// アカウント設定
				path: '/accounts/setting',
				name: 'AccountSetting',
				meta: {
					title: 'AccountSetting',
				},
				component: () => import('../views/Accounts/Setting.vue')
			},

			/**
			 * ユーザー管理
			 */
			{
				// ユーザー一覧
				path: 'system_users',
				name: 'SystemUsers',
				meta: {
					title: 'SystemUsers',
				},
				component: () => import('../views/SystemUsers/Index.vue')
			},
			{
				// ユーザー詳細
				path: 'system_user/:id',
				name: 'ShowSystemUser',
				meta: {
					title: 'ShowSystemUser',
				},
				component: () => import('../views/SystemUsers/Show.vue')
			},
			{
				// ユーザーアカウント編集
				path: 'system_user/edit/:id',
				name: 'EditSystemUser',
				meta: {
					title: 'EditSystemUser',
				},
				component: () => import('../views/SystemUsers/Edit.vue')
			},

			/**
			 * ユーザーアカウント管理
			 */
			{
				// ユーザーアカウント一覧
				path: 'users',
				name: 'Users',
				meta: {
					title: 'Users',
				},
				component: () => import('../views/Users/Index.vue')
			},
			{
				// ユーザーアカウント登録
				path: 'user/create',
				name: 'CreateUser',
				meta: {
					title: 'CreateUser',
				},
				component: () => import('../views/Users/Create.vue')
			},
			{
				// ユーザーアカウント詳細
				path: 'user/:id',
				name: 'ShowUser',
				meta: {
					title: 'ShowUser',
				},
				component: () => import('../views/Users/Show.vue')
			},
			{
				// ユーザーアカウント編集
				path: 'user/edit/:id',
				name: 'EditUser',
				meta: {
					title: 'EditUser',
				},
				component: () => import('../views/Users/Edit.vue')
			},
			{
				// 設定画面
				path: 'setting',
				name: 'Setting',
				meta: {
					title: 'Setting',
				},
				component: () => import('../views/Users/Setting.vue')
			},
			{
				// パスワード変更画面
				path: 'update_password',
				name: 'ChangePassword',
				meta: {
					title: 'ChangePassword',
				},
				component: () => import('../views/Users/ChangePassword.vue')
			},

			/**
			 * グループ管理
			 */
			{
				// グループ一覧
				path: 'groups',
				name: 'Groups',
				meta: {
					title: 'Groups',
				},
				component: () => import('../views/Groups/Index.vue')
			},
			{
				// グループ詳細
				path: 'group/:id',
				name: 'ShowGroup',
				meta: {
					title: 'ShowGroup',
				},
				component: () => import('../views/Groups/Show.vue')
			},
			{
				// グループ登録
				path: 'group/create',
				name: 'CreateGroup',
				meta: {
					title: 'CreateGroup',
				},
				component: () => import('../views/Groups/Create.vue')
			},
			{
				// グループ編集
				path: 'group/edit/:id',
				name: 'EditGroup',
				meta: {
					title: 'EditGroup',
				},
				component: () => import('../views/Groups/Edit.vue')
			},

			/**
			 * 動画管理
			 */
			{
				// 動画一覧
				path: 'movies',
				name: 'Movies',
				meta: {
					title: 'Movies',
				},
				component: () => import('../views/Movies/Index.vue')
			},
			{
				// 動画登録
				path: 'movie/create',
				name: 'CreateMovie',
				meta: {
					title: 'CreateMovie',
				},
				component: () => import('../views/Movies/Create.vue')
			},
			{
				// 動画詳細
				path: 'movie/:id',
				name: 'ShowMovie',
				meta: {
					title: 'ShowMovie',
				},
				component: () => import('../views/Movies/Show.vue')
			},
			{
				// 動画編集
				path: 'movie/edit/:id',
				name: 'EditMovie',
				meta: {
					title: 'EditMovie',
				},
				component: () => import('../views/Movies/Edit.vue')
			},

			/**
			 * システムのおしらせ管理
			 */
			{
				// システムのおしらせ一覧
				path: 'system_notifications',
				name: 'SystemNotifications',
				meta: {
					title: 'SystemNotifications',
				},
				component: () => import('../views/SystemNotifications/Index.vue')
			},
			{
				// システムのおしらせ登録
				path: 'system_notifications/create',
				name: 'CreateSystemNotification',
				meta: {
					title: 'CreateSystemNotification',
				},
				component: () => import('../views/SystemNotifications/Create.vue')
			},
			{
				// システムのおしらせ編集
				path: 'system_notifications/edit/:id',
				name: 'EditSystemNotification',
				meta: {
					title: 'EditSystemNotification',
				},
				component: () => import('../views/SystemNotifications/Edit.vue')
			},
			{
				// システムのおしらせ詳細
				path: 'system_notifications/:id',
				name: 'ShowSystemNotification',
				meta: {
					title: 'ShowSystemNotification',
				},
				component: () => import('../views/SystemNotifications/Show.vue')
			},

			/**
			 * アカウントのおしらせ管理
			 */
			{
				// アカウントのおしらせ一覧
				path: 'account_notifications',
				name: 'AccountNotifications',
				meta: {
					title: 'AccountNotifications',
				},
				component: () => import('../views/AccountNotifications/Index.vue')
			},
			{
				// アカウントのおしらせ登録
				path: 'account_notifications/create',
				name: 'CreateAccountNotification',
				meta: {
					title: 'CreateAccountNotification',
				},
				component: () => import('../views/AccountNotifications/Create.vue')
			},
			{
				// アカウントのおしらせ編集
				path: 'account_notifications/edit/:id',
				name: 'EditAccountNotification',
				meta: {
					title: 'EditAccountNotification',
				},
				component: () => import('../views/AccountNotifications/Edit.vue')
			},
			{
				// アカウントのおしらせ詳細
				path: 'account_notifications/:id',
				name: 'ShowAccountNotification',
				meta: {
					title: 'ShowAccountNotification',
				},
				component: () => import('../views/AccountNotifications/Show.vue')
			},

			{
				/**
				 * YouTube
				 */
				path: 'youtube',
				component: Youtube,
				children: [
					{
						// チャンネル一覧（選択画面）
						path: 'channel',
						name: 'YoutubeChannel',
						meta: {
							title: 'Youtube Channel',
						},
						component: () => import('../views/Youtube/Account/Index.vue')
					},
					{
						// チャンネル詳細
						path: 'channel/show/:id',
						name: 'ShowYoutubeChannel',
						meta: {
							title: 'Show Youtube Channel',
						},
						component: () => import('../views/Youtube/Account/Show.vue')
					},
					{
						// チャンネル登録
						path: 'channel/create',
						name: 'CreateYoutubeChannel',
						meta: {
							title: 'Create Youtube Channel',
						},
						component: () => import('../views/Youtube/Account/Create.vue')
					},
					{
						// 動画一覧
						path: 'video',
						name: 'YoutubeVideo',
						meta: {
							title: 'Youtube Video',
						},
						component: () => import('../views/Youtube/video/Index.vue')
					},
					{
						// 動画取り込み
						path: 'video/load',
						name: 'LoadYoutubeVideo',
						meta: {
							title: 'Load Youtube Video',
						},
						component: () => import('../views/Youtube/video/Load.vue')
					},
					{
						// 動画詳細
						path: 'video/show/:id',
						name: 'ShowYoutubeVideo',
						meta: {
							title: 'Show Youtube Video',
						},
						component: () => import('../views/Youtube/video/Show.vue')
					},
					{
						// 新規動画投稿
						path: 'video/create',
						name: 'CreateYoutubeVideo',
						meta: {
							title: 'Create Youtube Video',
						},
						component: () => import('../views/Youtube/video/Create.vue')
					},
					{
						// 動画編集
						path: 'video/edit/:id',
						name: 'EditYoutubeVideo',
						meta: {
							title: 'Edit Youtube Video',
						},
						component: () => import('../views/Youtube/video/Edit.vue')
					},
					{
						// 再生リスト
						path: 'playlist',
						name: 'YoutubePlaylist',
						meta: {
							title: 'Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Index.vue')
					},
					{
						// 再生リスト取り込み
						path: 'playlist/load',
						name: 'LoadYoutubePlaylist',
						meta: {
							title: 'Load Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Load.vue')
					},
					{
						// 再生リスト詳細
						path: 'playlist/show/:id',
						name: 'ShowYoutubePlaylist',
						meta: {
							title: 'Show Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Show.vue')
					},
					{
						// 再生リスト追加
						path: 'playlist/create',
						name: 'PostYoutubePlaylist',
						meta: {
							title: 'Create Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Create.vue')
					},
					{
						// 再生リスト編集
						path: 'playlist/edit/:id',
						name: 'EditYoutubePlaylist',
						meta: {
							title: 'Edit Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Edit.vue')
					},
				]
			},
			{
				/**
				 * Eq
				 */
				path: 'eq',
				component: Eq,
				children: [
					{
						// アカウント管理
						path: 'account/:id',
						name: 'EditEqAccount',
						meta: {
							title: 'Eq Account',
						},
						component: () => import('../views/Eq/Account/Edit.vue')
					},
					{
						// 新規配信登録
						path: 'delivery/create',
						name: 'CreateEqDelivery',
						meta: {
							title: 'Create Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Create.vue')
					},
					{
						// 配信情報編集
						path: 'delivery/edit/:id',
						name: 'EditEqDelivery',
						meta: {
							title: 'Edit Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Edit.vue')
					},
					{
						// 配信情報表示
						path: 'delivery/show/:id',
						name: 'ShowEqDelivery',
						meta: {
							title: 'Show Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Show.vue')
					},
					{
						// 配信一覧表示
						path: 'delivery',
						name: 'Equipmedia',
						meta: {
							title: 'Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Index.vue')
					},
					{
						// 配信取り込み表示
						path: 'delivery/load',
						name: 'LoadEqDelivery',
						meta: {
							title: 'Load Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Load.vue')
					},
				]
			},
			{
				/**
				 * FTP一覧
				 *
				 */
				path: 'ftp',
				name: 'Ftp',
				meta: {
					title: 'List Ftp Process',
				},
				component: () => import('../views/Ftp/Index.vue')
			},
			{
				/**
				 * FTP詳細
				 *
				 */
				path: 'ftp/show/:id',
				name: 'ShowFtp',
				meta: {
					title: 'Show Ftp Process',
				},
				component: () => import('../views/Ftp/Show.vue')
			},
			{
				/**
				 * EQCSVインポート一覧
				 *
				 */
				path: 'csvimport/eq',
				name: 'EqCsvImport',
				meta: {
					title: 'List EqCsvImport Process',
				},
				component: () => import('../views/CsvImport/Eq/Index.vue')
			},
			{
				/**
				 * EQCSVインポート詳細
				 *
				 */
				path: 'csvimport/eq/show/:id',
				name: 'ShowEqCsvImport',
				meta: {
					title: 'Show EqCsvImport Process',
				},
				component: () => import('../views/CsvImport/Eq/Show.vue')
			},
            {
                /**
                 * YoutubeCSVインポート一覧
                 *
                 */
                path: 'csvimport/youtube',
                name: 'YoutubeCsvImport',
                meta: {
                    title: 'List YoutubeCsvImport Process',
                },
                component: () => import('../views/CsvImport/Youtube/Index.vue')
            },
            {
                /**
                 * Youtubeインポート詳細
                 *
                 */
                path: 'csvimport/youtube/show/:id',
                name: 'ShowYoutubeCsvImport',
                meta: {
                    title: 'Show YoutubeCsvImport Process',
                },
                component: () => import('../views/CsvImport/Youtube/Show.vue')
            },
			// {
			// 	/**
			// 	 * CsvImport
			// 	 */
			// 	path: 'csvimport',
			// 	component: CsvImport,
			// 	children: [
			// 		{
			// 			/**
			// 			 * EQ一覧
			// 			 *
			// 			 */
			// 			path: 'eq',
			// 			name: 'EqCsvImport',
			// 			meta: {
			// 				title: 'List EqCsvImport Process',
			// 			},
			// 			component: () => import('../views/CsvImport/Eq/Index.vue')
			// 		},
			// 		{
			// 			/**
			// 			 * EQ詳細
			// 			 *
			// 			 */
			// 			path: 'eq/show/:id',
			// 			name: 'ShowEqCsvImport',
			// 			meta: {
			// 				title: 'Show ShowEqCsvImport Process',
			// 			},
			// 			component: () => import('../views/CsvImport/Eq/Show.vue')
			// 		},
			// 	],
			// },

			/**
			 * ログ管理
			 */
			{
				// ログ一覧
				path: 'logs',
				name: 'ProcessLogs',
				meta: {
					title: 'ProcessLogs',
				},
				component: () => import('../views/ProcessLogs/Index.vue')
			},

		]
	},
	{
		path: "/:catchAll(.*)",
		name: 'NotFound',
		meta: {
			title: 'Not Found',
		},
		component: () => import('../views/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
