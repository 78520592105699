/** ログアウト */
import CONSTANTS from "@/resources/constants.json";

export function logout(userId) {
    const url = new URL(
        `${process.env.VUE_APP_API_DOMAIN}/api/v1/auth/logout`
    );

    let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
    };

    const params = {
        user_id: userId
    };

    fetch(url, {
        method: "POST",
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(params)
    })

        .then(response => {
            document.cookie = `${CONSTANTS.api_token_name}=; max-age=0;`;
            return response.json();
        })

        .then(() => {
            window.location.href = '/login';
        })

        .catch(() => {
            this.$emit('showError', `${CONSTANTS.errors.unexpected_error}`);
        })

        .finally(() => {

        });
}
