/**
 * 非同期にresizeイベントを処理する
 */
const OriginalResizeObserver = window.ResizeObserver;

class AsyncResizeObserver {
    private observer: ResizeObserver;

    constructor(callback: ResizeObserverCallback) {
        this.observer = new OriginalResizeObserver(entries => {
            requestAnimationFrame(() => {
                callback(entries, this.observer);
            });
        });
    }

    observe(target: Element, options?: ResizeObserverOptions) {
        this.observer.observe(target, options);
    }

    unobserve(target: Element) {
        this.observer.unobserve(target);
    }

    disconnect() {
        this.observer.disconnect();
    }
}

export default AsyncResizeObserver;
